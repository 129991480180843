import React, { lazy, Suspense } from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
const IMAGE_URL = "http://31.220.60.227/React-site/src";
// Pages
const Home = React.lazy(() => import("./components/pages/Home"));
const Homeone = React.lazy(() => import("./components/pages/Homeone"));
const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
const Homethree = React.lazy(() => import("./components/pages/Homethree"));
const Homefour = React.lazy(() => import("./components/pages/Homefour"));
const About = React.lazy(() => import("./components/pages/About"));
const AddRefund = React.lazy(() => import("./components/pages/AddRefund"));
const Partner = React.lazy(() => import("./components/pages/Partner"));
const PartnerLogin = React.lazy(() =>
  import("../src/components/sections/partner/PartnerLogin")
);
const PartnerForget = React.lazy(() =>
  import("../src/components/sections/partner/PartnerForget")
);
const DeliveryPartner = React.lazy(() =>
  import("./components/pages/DeliveryPartner")
);
const DeliveryPartnerLogin = React.lazy(() =>
  import("../src/components/sections/delivery/DeliveryPartnerLogin")
);
const DeliveryPartnerForget = React.lazy(() =>
  import("../src/components/sections/delivery/DeliveryPartnerForget")
);
const Blog = React.lazy(() => import("./components/pages/Blog"));
const Blogtwo = React.lazy(() => import("./components/pages/Blogtwo"));
const Blogdetails = React.lazy(() => import("./components/pages/Blogdetails"));
const Restaurant = React.lazy(() => import("./components/pages/Restaurant"));
const Restaurantone = React.lazy(() =>
  import("./components/pages/Restaurantone")
);
const Restauranttwo = React.lazy(() =>
  import("./components/pages/Restauranttwo")
);
const Addrestaurant = React.lazy(() =>
  import("./components/pages/Addrestaurant")
);
const Checkout = React.lazy(() => import("./components/pages/Checkout"));
const CheckoutGrocery = React.lazy(() =>
  import("./components/pages/CheckoutGrocery")
);
const CheckoutPackage = React.lazy(() =>
  import("./components/pages/CheckoutPackage")
);
const Domestic_services = React.lazy(() =>
  import("./components/pages/CheckoutDomesticServices")
);
const InternationalServices_services = React.lazy(() =>
  import("./components/pages/CheckoutInternationalServices")
);
const LuggageDelivery_services = React.lazy(() =>
  import("./components/pages/CheckoutLuggageDeliveryServices")
);
const Parcel_services = React.lazy(() =>
  import("./components/pages/CheckoutParcelService")
);
const Exdeals = React.lazy(() => import("./components/pages/Exdeals"));
const Geolocator = React.lazy(() => import("./components/pages/Geolocator"));
const Listview = React.lazy(() => import("./components/pages/Listview"));
const Login = React.lazy(() => import("./components/pages/Login"));
const Partnerlog = React.lazy(() => import("./components/pages/Partnerlog"));
const DeliveryPartnerlog = React.lazy(() =>
  import("./components/pages/DeliveryPartnerlog")
);
const Domestic = React.lazy(() => import("./components/pages/Domestic"));
const DomesticNew = React.lazy(() => import("./components/pages/DomesticNew"));
const Forget = React.lazy(() => import("./components/pages/Forget"));
const International = React.lazy(() =>
  import("./components/pages/International")
);
const Luggage = React.lazy(() => import("./components/pages/Luggage"));
const Parcel = React.lazy(() => import("./components/pages/Parcel"));
const Rush = React.lazy(() => import("./components/pages/Rush"));
const OrderSuccess = React.lazy(() =>
  import("./components/pages/OrderSuccess")
);
const TrackYourOrder = React.lazy(() =>
  import("./components/pages/TrackYourOrder")
);

const OrderSuccessGrocery = React.lazy(() =>
  import("./components/pages/OrderSuccessGrocery")
);
const OrderSuccessPackage = React.lazy(() =>
  import("./components/pages/OrderSucessPackageMain")
);
const Grocery = React.lazy(() => import("./components/pages/Grocery"));
const Help = React.lazy(() => import("./components/pages/Help"));
const Terms = React.lazy(() => import("./components/pages/Terms"));
const DeliveryTerms = React.lazy(() =>
  import("./components/pages/DeliveryTerms")
);
const Refund_Policy = React.lazy(() =>
  import("./components/pages/Refund_Policy")
);
const Privacy = React.lazy(() => import("./components/pages/Privacy"));
const Cart = React.lazy(() => import("./components/pages/Cart"));
const Invoice = React.lazy(() => import("./components/pages/Invoice"));
const Grocery_store = React.lazy(() =>
  import("./components/pages/Grocery_store")
);
const Gifts = React.lazy(() => import("./components/pages/Gifts"));
const Giftscategories = React.lazy(() =>
  import("./components/pages/Giftscategories")
);
const AccessoriesCategories = React.lazy(() =>
  import("./components/pages/AccessoriesCategories")
);
const Store = React.lazy(() => import("./components/pages/Store"));
const Tabletest = React.lazy(() => import("./components/pages/Tabletest"));
const Accessories = React.lazy(() => import("./components/pages/Accessories"));
const Package = React.lazy(() => import("./components/pages/Package"));
const Whoweare = React.lazy(() => import("./components/pages/Whoweare"));
const Whatweoffer = React.lazy(() => import("./components/pages/Whatweoffer"));
const Ourvalues = React.lazy(() => import("./components/pages/Ourvalues"));
const Techdriven = React.lazy(() => import("./components/pages/Techdriven"));
const Transparency = React.lazy(() =>
  import("./components/pages/Transparency")
);
const Safety = React.lazy(() => import("./components/pages/Safety"));
const Careers = React.lazy(() => import("./components/pages/Careers"));
const Faq = React.lazy(() => import("./components/pages/Faq"));
const Account = React.lazy(() => import("./components/pages/Profile"));
const Register = React.lazy(() => import("./components/pages/Register"));
const Wishlist = React.lazy(() => import("./components/pages/Wishlist"));
const Profile = React.lazy(() => import("./components/pages/Profile"));

// const Profile1 = lazy(() =>
//   import("../src/components/profile/settings/overview/Profile")
// );
// const Account1 = lazy(() =>
//   import("../src/components/profile/settings/overview/Account")
// );
// const Transaction = lazy(() =>
//   import("../src/components/profile/settings/overview/Transaction")
// );
// const Order = lazy(() =>
//   import("../src/components/profile/settings/overview/Order")
// );
// const Password = lazy(() =>
//   import("../src/components/profile/settings/overview/Passwoard")
// );
// const SocialProfiles = lazy(() =>
//   import("../src/components/profile/settings/overview/SocialProfile")
// );
// const Notification = lazy(() =>
//   import("../src/components/profile/settings/overview/Notification")
// );

const Allrestaurant = React.lazy(() =>
  import("./components/pages/Allrestaurant")
);
const AllGroceryStores = React.lazy(() =>
  import("./components/pages/AllGroceryStores")
);
const GroceryCategories = React.lazy(() =>
  import("./components/pages/GroceryCategories")
);
const Cuisine = React.lazy(() => import("./components/pages/Cuisine"));
const Verification = React.lazy(() =>
  import("./components/pages/Verification")
);
const Accverification = React.lazy(() =>
  import("./components/pages/Accverification")
);
const Accverification_forgotpassword = React.lazy(() =>
  import("./components/pages/AccountverificationForgotPassword")
);
const Accverificationpartner = React.lazy(() =>
  import("./components/pages/Accverificationpartner")
);
const Reset = React.lazy(() => import("./components/pages/Reset"));
const Orderdetails = React.lazy(() =>
  import("./components/pages/Orderdetails")
);
const Wallet = React.lazy(() =>
  import("./components/pages/Wallet")
);
const Orderhistory = React.lazy(() =>
  import("./components/pages/Orderhistory")
);
const Ordersummary = React.lazy(() =>
  import("./components/pages/Ordersummary")
);
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Error = React.lazy(() => import("./components/pages/Error"));
const Maintenance = React.lazy(() => import("./components/pages/Maintenance"));

function App() {
  if (window.location.host != "localhost:3000") {
    if (window.location.protocol.toLowerCase() !== "https:") {
      const url = `https://${window.location.host}`;
      window.location.replace(url);
    }
  }

  const { path } = useRouteMatch();

  return (
    // <Router basename={'/themes/themeforest/react/quickmunch'}>
    <Router basename={"/"}>
      <Suspense fallback={<div id="cover-spin"></div>}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home-v1" component={Homeone} />
          <Route exact path="/home-v2" component={Hometwo} />
          <Route exact path="/home-v3" component={Homethree} />
          <Route exact path="/home-v4" component={Homefour} />
          <Route exact path="/about" component={About} />
          <Route exact path="/add_refund" component={AddRefund} />
          <Route exact path={`/partner`} component={Partner} />
          <Route exact path={`/partnerlogin`} component={PartnerLogin} />
          <Route exact path={`/partnerforget`} component={PartnerForget} />
          <Route exact path={`/deliverypartner`} component={DeliveryPartner} />
          <Route
            exact
            path={`/deliverypartnerlogin`}
            component={DeliveryPartnerLogin}
          />
          <Route
            exact
            path={`/deliverypartnerforget`}
            component={DeliveryPartnerForget}
          />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/blog-v2" component={Blogtwo} />
          <Route exact path="/blog-details" component={Blogdetails} />
          <Route exact path="/restaurant/:id" component={Restaurant} />
          <Route exact path="/restaurant-v1" component={Restaurantone} />
          <Route exact path="/restaurant-v2" component={Restauranttwo} />
          <Route exact path="/add-restaurant" component={Addrestaurant} />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/grocery_checkout" component={CheckoutGrocery} />
          <Route exact path="/package_checkout" component={CheckoutPackage} />
          <Route
            exact
            path="/domestic_services"
            component={Domestic_services}
          />
          <Route
            exact
            path="/international_services"
            component={InternationalServices_services}
          />
          <Route
            exact
            path="/luggage_services"
            component={LuggageDelivery_services}
          />
          <Route exact path="/parcel_services" component={Parcel_services} />
          <Route exact path="/ex-deals" component={Exdeals} />
          <Route exact path="/geo-locator" component={Geolocator} />
          <Route exact path="/list-view" component={Listview} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/Partnerlog" component={Partnerlog} />
          <Route
            exact
            path="/DeliveryPartnerlog"
            component={DeliveryPartnerlog}
          />
          <Route exact path="/domestic" component={Domestic} />
          <Route exact path="/domestic-new" component={DomesticNew} />
          <Route exact path="/forget" component={Forget} />
          <Route exact path="/international" component={International} />
          <Route exact path="/luggage" component={Luggage} />
          <Route exact path="/parcel" component={Parcel} />
          <Route exact path="/rush" component={Rush} />
          <Route exact path="/ordersuccess/:id" component={OrderSuccess} />
          <Route exact path="/trackyourorder" component={TrackYourOrder} />
          <Route
            exact
            path="/ordersuccessgrocery/:id"
            component={OrderSuccessGrocery}
          />
          <Route
            exact
            path="/ordersuccess_package/:id"
            component={OrderSuccessPackage}
          />
          <Route exact path="/grocery" component={Grocery} />
          <Route exact path="/help" component={Help} />
          <Route exact path="/terms" component={Terms} />
          <Route
            exact
            path="/delivery_partner_terms"
            component={DeliveryTerms}
          />
          <Route exact path="/refund-policy" component={Refund_Policy} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/invoice" component={Invoice} />
          <Route exact path="/grocery_store/:id" component={Grocery_store} />
          <Route exact path="/gifts" component={Gifts} />
          <Route exact path="/giftscategories" component={Giftscategories} />
          <Route
            exact
            path="/accessoriesCategories"
            component={AccessoriesCategories}
          />
          <Route exact path="/store/:id" component={Store} />
          <Route exact path="/tabletest" component={Tabletest} />
          <Route exact path="/accessories" component={Accessories} />
          <Route exact path="/package" component={Package} />
          <Route exact path="/whoweare" component={Whoweare} />
          <Route exact path="/Whatweoffer" component={Whatweoffer} />
          <Route exact path="/Ourvalues" component={Ourvalues} />
          <Route exact path="/Techdriven" component={Techdriven} />
          <Route exact path="/Transparency" component={Transparency} />
          <Route exact path="/Safety" component={Safety} />
          <Route exact path="/careers" component={Careers} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/account" component={Account} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/wishlist" component={Wishlist} />
          <Route exact path="/Profile" component={Profile} />
          <Route exact path="/restaurant" component={Allrestaurant} />
          <Route exact path="/Allgrocerystores" component={AllGroceryStores} />
          <Route
            exact
            path="/Grocerycategories/:id"
            component={GroceryCategories}
          />
          <Route exact path="/cuisine/:id" component={Cuisine} />
          <Route exact path="/verification/:id" component={Verification} />
          <Route exact path="/Accverification" component={Accverification} />
          <Route
            exact
            path="/Accverificationfp"
            component={Accverification_forgotpassword}
          />
          <Route
            exact
            path="/Accverificationpartner"
            component={Accverificationpartner}
          />
          <Route exact path="/reset-password/:id" component={Reset} />
          <Route exact path="/order-details" component={Orderdetails} /> 
          <Route exact path="/wallet" component={Wallet} /> 
          <Route exact path="/order-history" component={Orderhistory} />
          <Route exact path="/order-summary/:id" component={Ordersummary} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/error-page" component={Error} />
          <Route exact path="/maintenance" component={Maintenance} />

          {/* <Route exact path={`${path}/profile`} component={Profile1} />
          <Route exact path={`${path}/account`} component={Account1} />
          <Route exact path={`${path}/transaction`} component={Transaction} />
          <Route exact path={`${path}/order`} component={Order} />
          <Route exact path={`${path}/password`} component={Password} />
          <Route exact path={`${path}/social`} component={SocialProfiles} />
          <Route exact path={`${path}/notification`} component={Notification} /> */}

          <Route exact component={Error} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
